import {
  usePrivy,
  useWallets,
  getEmbeddedConnectedWallet,
} from "@privy-io/react-auth";
import "../App.css";
import { encodeFunctionData } from "viem";
import { abiRodNFT } from "../config/abi.contract";

function useRod() {
  const urlEndpoint = process.env.REACT_APP_FISH_BE_ENDPOINT;
  const { sendTransaction } = usePrivy();
  const { wallets } = useWallets();
  const embeddedWallet = getEmbeddedConnectedWallet(wallets);
  const rodNFTContractAddress = process.env.REACT_APP_ROD_NFT_CONTRACT_ADDRESS;

  const depositRod = async (rodIds) => {
    try {
      if (embeddedWallet && await embeddedWallet.isConnected()) {
        const data = encodeFunctionData({
          abi: abiRodNFT,
          functionName: "stakeRod",
          args: [rodIds],
        });

        const rodIdsList = rodIds.join(", ");
        const txUiConfig = {
          header: `Deposit Rod`,
          description: `Deposit Rod IDs: ${rodIdsList}`,
          buttonText: `Deposit`,
        };

        // Construct transaction request
        const transactionRequest = {
          to: rodNFTContractAddress,
          data: data,
        };

        const txReceipt = await sendTransaction(transactionRequest, txUiConfig);

        if (txReceipt.status === 1) {
          const backendResponse = await verifyTxHashAndProcessDepositRod(
            txReceipt.transactionHash
          );

          if (backendResponse.success) {
            return {
              success: true,
              message: "Verify successful",
              data: backendResponse.data,
            };
          } else {
            return { success: false, message: "Verify transaction failed" };
          }
        } else {
          console.error("Transaction failed:", txReceipt);
          return {
            success: false,
            message: "Transaction failed on blockchain",
          };
        }
      } else {
        console.error(
          `Transaction failed: embedded wallet ${embeddedWallet} is not connected`
        );
        return {
          success: false,
          message: `Embedded wallet is not connected, please try again`,
        };
      }
    } catch (error) {
      console.error("Send transaction failed:", error);
      return { success: false, message: "Send Transaction failed" };
    }
  };

  const withdrawRod = async (rodIds) => {
    try {
      if (embeddedWallet && await embeddedWallet.isConnected()) {
        const data = encodeFunctionData({
          abi: abiRodNFT,
          functionName: "unstakeRod",
          args: [rodIds],
        });

        const rodIdsList = rodIds.join(", ");
        const txUiConfig = {
          header: `Withdraw Rod`,
          description: `Withdraw Rod IDs: ${rodIdsList}`,
          buttonText: `Withdraw`,
        };

        // Construct transaction request
        const transactionRequest = {
          to: rodNFTContractAddress,
          data: data,
        };

        const txReceipt = await sendTransaction(transactionRequest, txUiConfig);

        if (txReceipt.status === 1) {
          const backendResponse = await verifyTxHashAndProcessWithdrawRod(
            txReceipt.transactionHash
          );

          if (backendResponse.success) {
            return {
              success: true,
              message: "Verify successful",
              data: backendResponse.data,
            };
          } else {
            return { success: false, message: "Verify transaction failed" };
          }
        } else {
          console.error("Transaction failed:", txReceipt);
          return {
            success: false,
            message: "Transaction failed on blockchain",
          };
        }
      } else {
        console.error(
          `Transaction failed: embedded wallet ${embeddedWallet} is not connected`
        );
        return {
          success: false,
          message: `Embedded wallet is not connected, please try again`,
        };
      }
    } catch (error) {
      console.error("Send transaction failed:", error);
      return { success: false, message: "Send Transaction failed" };
    }
  };

  const repairRod = async (rodIds) => {
    try {
      if (embeddedWallet && await embeddedWallet.isConnected()) {
        const data = encodeFunctionData({
          abi: abiRodNFT,
          functionName: "repairRods",
          args: [rodIds],
        });

        const rodIdsList = rodIds.join(", ");
        const txUiConfig = {
          header: `Repair Rod`,
          description: `Repair Rod IDs: ${rodIdsList}`,
          buttonText: `Repair`,
        };

        // Construct transaction request
        const transactionRequest = {
          to: rodNFTContractAddress,
          data: data,
        };

        const txReceipt = await sendTransaction(transactionRequest, txUiConfig);

        if (txReceipt.status === 1) {
          const backendResponse = await verifyTxHashAndProcessRepairRod(
            txReceipt.transactionHash
          );

          if (backendResponse.success) {
            return {
              success: true,
              message: "Verify successful",
              data: backendResponse.data,
            };
          } else {
            return { success: false, message: "Verify transaction failed" };
          }
        } else {
          console.error("Transaction failed:", txReceipt);
          return {
            success: false,
            message: "Transaction failed on blockchain",
          };
        }
      } else {
        console.error(
          `Transaction failed: embedded wallet ${embeddedWallet} is not connected`
        );
        return {
          success: false,
          message: `Embedded wallet is not connected, please try again`,
        };
      }
    } catch (error) {
      console.error("Send transaction failed:", error);
      return { success: false, message: "Send Transaction failed" };
    }
  };

  const verifyTxHashAndProcessDepositRod = async (txHash) => {
    try {
      const response = await fetch(urlEndpoint + "/trading/deposit-nft-rod", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("fishAuth")}`, // Gửi JWT token trong header
        },
        body: JSON.stringify({ transactionHash: txHash }),
      });
      const data = await response.json();

      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Error when sending transactionHash to backend:", error);
      return { success: false, message: error.message };
    }
  };

  const verifyTxHashAndProcessWithdrawRod = async (txHash) => {
    try {
      const response = await fetch(urlEndpoint + "/trading/withdraw-nft-rod", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("fishAuth")}`, // Gửi JWT token trong header
        },
        body: JSON.stringify({ transactionHash: txHash }),
      });
      const data = await response.json();

      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Error when sending transactionHash to backend:", error);
      return { success: false, message: error.message };
    }
  };

  const verifyTxHashAndProcessRepairRod = async (txHash) => {
    try {
      const response = await fetch(urlEndpoint + "/trading/repair-nft-rod", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("fishAuth")}`, // Gửi JWT token trong header
        },
        body: JSON.stringify({ transactionHash: txHash }),
      });
      const data = await response.json();

      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Error when sending transactionHash to backend:", error);
      return { success: false, message: error.message };
    }
  };

  return { depositRod, withdrawRod, repairRod };
}

export default useRod;
