import {
  usePrivy,
  useWallets,
  getEmbeddedConnectedWallet,
} from "@privy-io/react-auth";
import "../App.css";
import { abiFishFactory, abiFishNFT } from "../config/abi.contract";
import { FishSignatureMinterNFT } from "../lib";
import { ethers } from "ethers";
import { encodeFunctionData } from "viem";
import { v4 as uuidv4 } from "uuid";

function useFish() {
  const urlEndpoint = process.env.REACT_APP_FISH_BE_ENDPOINT;
  const { provider, sendTransaction } = usePrivy();
  const { wallets } = useWallets();
  const embeddedWallet = getEmbeddedConnectedWallet(wallets);

  const signer = new ethers.Wallet(
    process.env.REACT_APP_SIGNER_PRIVATE_KEY,
    provider
  );

  const fishContractFactoryAddress =
    process.env.REACT_APP_FISH_NFT_CONTRACT_FACTORY_ADDRESS;

  const contract = new ethers.Contract(
    process.env.REACT_APP_FISH_NFT_CONTRACT_ADDRESS,
    abiFishNFT,
    provider
  );

  const signnatureMint = new FishSignatureMinterNFT({
    contract: contract,
    signer: signer,
  });

  const currentTime = Math.floor(Date.now() / 1000);

  const withdrawFish = async (fishIds, tokenIds) => {
    try {
      if (embeddedWallet && await embeddedWallet.isConnected()) {
        const fishIdsBytes32 = fishIds.map((fishId) =>
          ethers.utils.formatBytes32String(fishId)
        );
        const quantities = Array.from({ length: tokenIds.length }, () => 1);
        const uniqueId = uuidv4().slice(0, 31);
        const req = {
          to: embeddedWallet.address,
          fishIds: fishIdsBytes32,
          tokenIds,
          quantities,
          validityStartTimestamp: currentTime,
          validityEndTimestamp: currentTime + 3600,
          uid: ethers.utils.id(uniqueId),
        };

        const { signature } = await signnatureMint.createReq(
          req.to,
          req.fishIds,
          req.tokenIds,
          req.quantities,
          req.validityStartTimestamp,
          req.validityEndTimestamp,
          req.uid
        );

        const data = encodeFunctionData({
          abi: abiFishFactory,
          functionName: "withdrawOrMintBatchFishWithSignature",
          args: [req, signature],
        });

        const txUiConfig = {
          header: `Withdraw Fish`,
          description: `Withdraw fish`,
          buttonText: `Withdraw`,
        };

        // Construct transaction request
        const transactionRequest = {
          to: fishContractFactoryAddress,
          data: data,
        };

        const tx = await sendTransaction(transactionRequest, txUiConfig);

        if (tx.status === 1) {
          const backendResponse = await verifyTxHashAndProcessWithdrawFish(
            tx.transactionHash
          );

          if (backendResponse.success) {
            return {
              success: true,
              message: "Verify successful",
              data: backendResponse.data,
            };
          } else {
            return { success: false, message: "Verify transaction failed" };
          }
        } else {
          console.error("Transaction failed:", tx);
          return {
            success: false,
            message: "Transaction failed on blockchain",
          };
        }
      } else {
        console.error(
          `Transaction failed: embedded wallet ${embeddedWallet} is not connected`
        );
        return {
          success: false,
          message: `Embedded wallet is not connected, please try again`,
        };
      }
    } catch (error) {
      console.error("Send transaction failed:", error);
      return { success: false, message: "Send Transaction failed" };
    }
  };

  const depositFish = async (tokenIds) => {
    try {
      if (embeddedWallet && await embeddedWallet.isConnected()) {
        const data = encodeFunctionData({
          abi: abiFishFactory,
          functionName: "depositFish",
          args: [tokenIds],
        });

        const tokenIdsList = tokenIds.join(", ");
        const txUiConfig = {
          header: `Deposit Fish`,
          description: `Deposit Fish IDs: ${tokenIdsList}`,
          buttonText: `Deposit`,
        };

        // Construct transaction request
        const transactionRequest = {
          to: fishContractFactoryAddress,
          data: data,
        };

        const txReceipt = await sendTransaction(transactionRequest, txUiConfig);

        if (txReceipt.status === 1) {
          const backendResponse = await verifyTxHashAndProcessDepositFish(
            txReceipt.transactionHash
          );

          if (backendResponse.success) {
            return {
              success: true,
              message: "Verify successful",
              data: backendResponse.data,
            };
          } else {
            return { success: false, message: "Verify transaction failed" };
          }
        } else {
          console.error("Transaction failed:", txReceipt);
          return {
            success: false,
            message: "Transaction failed on blockchain",
          };
        }
      } else {
        console.error(
          `Transaction failed: embedded wallet ${embeddedWallet} is not connected`
        );
        return {
          success: false,
          message: `Embedded wallet is not connected, please try again`,
        };
      }
    } catch (error) {
      console.error("Send transaction failed:", error);
      return { success: false, message: "Send Transaction failed" };
    }
  };

  const burnFish = async (tokenIds) => {
    try {
      if (embeddedWallet && await embeddedWallet.isConnected()) {
        const data = encodeFunctionData({
          abi: abiFishFactory,
          functionName: "burnBatchFish",
          args: [tokenIds],
        });

        const tokenIdsList = tokenIds.join(", ");
        const txUiConfig = {
          header: `Burn Fish`,
          description: `Burn Fish IDs: ${tokenIdsList}`,
          buttonText: `Burn`,
        };

        // Construct transaction request
        const transactionRequest = {
          to: fishContractFactoryAddress,
          data: data,
        };

        const txReceipt = await sendTransaction(transactionRequest, txUiConfig);

        if (txReceipt.status === 1) {
          // const backendResponse = await verifyTxHashAndProcessDepositFish(
          //   txReceipt.transactionHash
          // );

          // if (backendResponse.success) {
          //   return {
          //     success: true,
          //     message: "Verify successful",
          //     data: backendResponse.data,
          //   };
          // } else {
          //   return { success: false, message: "Verify transaction failed" };
          // }

          return {
            success: true,
            message: "Verify successful"
          };
        } else {
          console.error("Transaction failed:", txReceipt);
          return {
            success: false,
            message: "Transaction failed on blockchain",
          };
        }
      } else {
        console.error(
          `Transaction failed: embedded wallet ${embeddedWallet} is not connected`
        );
        return {
          success: false,
          message: `Embedded wallet is not connected, please try again`,
        };
      }
    } catch (error) {
      console.error("Send transaction failed:", error);
      return { success: false, message: "Send Transaction failed" };
    }
  };

  const verifyTxHashAndProcessWithdrawFish = async (txHash) => {
    try {
      const response = await fetch(urlEndpoint + "/trading/withdraw-nft-fish", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("fishAuth")}`, // Gửi JWT token trong header
        },
        body: JSON.stringify({ transactionHash: txHash }),
      });
      const data = await response.json();

      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Error when sending transactionHash to backend:", error);
      return { success: false, message: error.message };
    }
  };

  const verifyTxHashAndProcessDepositFish = async (txHash) => {
    try {
      const response = await fetch(urlEndpoint + "/trading/deposit-nft-fish", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("fishAuth")}`, // Gửi JWT token trong header
        },
        body: JSON.stringify({ transactionHash: txHash }),
      });
      const data = await response.json();

      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Error when sending transactionHash to backend:", error);
      return { success: false, message: error.message };
    }
  };

  return { withdrawFish, depositFish, burnFish };
}

export default useFish;
