import {
  getAccessToken,
  useLogin,
  usePrivy,
  useSetWalletRecovery,
} from "@privy-io/react-auth";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../App.css";
import { useGlobalContext } from "../context";
import { getDeviceId, removeDeviceId } from "../utils";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
function useCustomLogin() {
  const { ready, authenticated, logout } = usePrivy();
  const { setIsAuthenticated, setErrorMessage } = useGlobalContext();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const urlEndpoint = process.env.REACT_APP_FISH_BE_ENDPOINT;
  const urlWebsocketEndpoint = process.env.REACT_APP_FISH_WEBSOCKET_ENDPOINT;

  const next = search.get("next") ? window.atob(search.get("next")) : null;
  let codeQuery = search.get("code") || "";
  const deviceId = getDeviceId();
  const { setWalletRecovery } = useSetWalletRecovery({
    onError: async (error) => {
      handleLoginError(error);
    },
  });

  if (!codeQuery && next) {
    const urlParams = new URLSearchParams(next.split("?")[1]);
    codeQuery = urlParams.get("code") || "";
  }

  const handleGetTeleRefCode = () => {
    console.log("TELEGRAM get Refcode");
    var webAppInitData = window.Telegram.WebView.initParams.tgWebAppData;
    var webAppInitDataUnsafe =
      window.Telegram.Utils.urlParseQueryString(webAppInitData);

    for (var key in webAppInitDataUnsafe) {
      var val = webAppInitDataUnsafe[key];
      try {
        if (
          (val.substr(0, 1) === "{" && val.substr(-1) === "}") ||
          (val.substr(0, 1) === "[" && val.substr(-1) === "]")
        ) {
          webAppInitDataUnsafe[key] = JSON.parse(val);
          const refCode = window.Telegram.WebApp.initDataUnsafe.start_param;
          const teleUserID = webAppInitDataUnsafe.user.id;
          const teleFistName = webAppInitDataUnsafe.user.first_name;
          return { refCode, teleUserID, teleFistName };
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const { login } = useLogin({
    onError: async (err) => {
      console.log("Error", err);
      logout();
    },
    onOAuthLoginComplete: async (oAuthTokens) => {
      if (oAuthTokens.provider === "twitter") {
        localStorage.setItem("twitter_access_token", oAuthTokens.accessToken);
        localStorage.setItem("twitter_refresh_token", oAuthTokens.refreshToken);
      }
    },
    onComplete: async (user) => {
      const privyToken = await getPrivyAccessToken();
      let url_login = `${urlEndpoint}/auth/login`;
      let refCode = null;
      let teleUserId = null;
      let teleName = null;

      if (window.Telegram.WebView.initParams.tgWebAppData !== undefined) {
        const teleInfo = handleGetTeleRefCode();
        refCode = teleInfo.refCode;
        teleUserId = teleInfo.teleUserID;
        teleName = teleInfo.teleFistName;
      }

      try {
        if (!(await alreadyHasUserOwnedRecovery(user))) {
          await setWalletRecovery();
        }
        const { data } = await axios.post(
          url_login,
          JSON.stringify({
            deviceId,
            teleUserId,
            teleName,
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "x-privy-token": privyToken,
            },
          }
        );

        if (refCode) {
          await axios.post(
            `${urlEndpoint}/reference-code/verify?code=${refCode}`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${data?.tokens.access.token}`,
              },
            }
          );
        }
        handleSuccessfulLogin(data);
        removeDeviceId();
      } catch (error) {
        removeDeviceId();
        handleLoginError(error);
      }
    },
  });

  const getPrivyAccessToken = async () => {
    const accessToken = await getAccessToken();
    return accessToken;
  };

  const guestLogin = async () => {
    const url_guest_login = `${urlEndpoint}/auth/guest-login`;
    let refCode = null;
    let teleUserId = null;
    let teleName = null;
    if (window.Telegram.WebView.initParams.tgWebAppData !== undefined) {
      const teleInfo = handleGetTeleRefCode();
      refCode = teleInfo.refCode;
      teleUserId = teleInfo.teleUserID;
      teleName = teleInfo.teleFistName;
    }

    try {
      const { data } = await axios.post(
        url_guest_login,
        JSON.stringify({ deviceId, teleUserId, teleName }),
        { headers: { "Content-Type": "application/json" } }
      );
      if (refCode) {
        await axios.post(
          `${urlEndpoint}/reference-code/verify?code=${refCode}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${data?.tokens.access.token}`,
            },
          }
        );
      }
      handleSuccessfulLogin(data);
    } catch (error) {
      localStorage.setItem("isGuest", false);
      handleLoginError(error);
    }
  };

  const handleSuccessfulLogin = async (data) => {
    localStorage.setItem("fishAuth", data?.tokens.access.token);
    localStorage.setItem("refreshToken", data?.tokens.refresh.token);
    localStorage.setItem("isLoggedOut", false);
    localStorage.setItem("userId", data?.user.id);
    console.log("Logged in FISH_BE");
    localStorage.setItem("isGuest", data?.user.isGuest);
    setIsAuthenticated(authenticated);
    localStorage.setItem("isAuthenticated", true);
    localStorage.setItem("fish_be_endpoint", urlEndpoint);
    localStorage.setItem("fish_websocket_endpoint", urlWebsocketEndpoint);

    if (codeQuery) {
      try {
        const url_verifyCode = `${urlEndpoint}/reference-code/verify?code=${codeQuery}`;
        await axios.post(
          url_verifyCode,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${data?.tokens.access.token}`,
            },
          }
        );
      } catch (error) {
        console.log("Code verification failed:", error);
      }
    }
    navigate(next ?? "/");
    if (!next && data?.user.isGuest) {
      serviceWorkerRegistration.servicesWorkerHandlePageLoad();
    }
  };

  const alreadyHasUserOwnedRecovery = async (user) => {
    if (!user) {
      throw new Error("user is not provided");
    }
    const embeddedWallet = user.linkedAccounts.find(
      (account) =>
        account.type === "wallet" && account.walletClientType === "privy"
    );
    if (!embeddedWallet) {
      throw new Error("No embedded wallet found");
    }
    return embeddedWallet.recoveryMethod !== "privy";
  };

  const handleLoginError = async (error) => {
    if (error && error.response && error.response.status === 401) {
      try {
        const url_refresh_token = `${urlEndpoint}/auth/refresh-tokens`;
        const { data } = await axios.post(
          url_refresh_token,
          {
            refreshToken: localStorage.getItem("refreshToken"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        localStorage.setItem("fishAuth", data?.tokens.access.token);
        localStorage.setItem("refreshToken", data?.tokens.refresh.token);
      } catch (error) {
        handleLogout(error);
      }
    } else {
      handleLogout(error);
    }
  };

  const handleLogout = (error) => {
    logout();
    setIsAuthenticated(false);
    localStorage.removeItem("fishAuth");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("isAuthenticated");
    setErrorMessage(error.response?.data.message ?? error.message);
    navigate("/login");
  };

  return { login, guestLogin, ready };
}

export default useCustomLogin;
