import axios from "axios";

const isNotificationAndSWSupported = () => {
  if (!("serviceWorker" in navigator)) {
    console.error("Service Workers are not supported in this browser.");
    return false;
  }
  if (!("PushManager" in window)) {
    console.error("Push Notifications are not supported in this browser.");
    return false;
  }
  return true;
};

export const registerSW = async () => {
  if (!isNotificationAndSWSupported()) return;

  const isNotificationsEnabled =
    localStorage.getItem("notificationsEnabled") === "true";
  if (!isNotificationsEnabled) {
    return;
  }

  try {
    const registration = await navigator.serviceWorker.register(
      "/custom-sw.js"
    );
    const subscription = await getOrCreateSubscription(registration);
    await saveSubscriptionToServer(subscription);
    return registration;
  } catch (error) {
    console.error("Service Worker registration failed: ", error);
  }
};

const saveSubscriptionToServer = async (subscription) => {
  const token = localStorage.getItem("fishAuth");
  if (!token) return;
  const currentEndpoint = localStorage.getItem("subscriptionEndpoint");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_FISH_BE_ENDPOINT}/users/save-subscription`,
      { subscription, currentEndpoint },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data) {
      localStorage.setItem("subscriptionId", response.data.id);
      localStorage.setItem("subscriptionEndpoint", subscription.endpoint);
    }
    return response.data;
  } catch (error) {
    console.error("Failed to save subscription:", error);
    return null;
  }
};

const removeSubscriptionToServer = async (authToken) => {
  const token = authToken || localStorage.getItem("fishAuth");
  const subscriptionId = localStorage.getItem("subscriptionId");
  if (!token || !subscriptionId) {
    console.log("Token or subscriptionId is missing.");
    return;
  }
  try {
    await axios.delete(
      `${process.env.REACT_APP_FISH_BE_ENDPOINT}/users/remove-subscription/${subscriptionId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.error("Failed to delete subscription:", error);
  }
};

const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const unsubscribePush = async (subscriptionEndpoint) => {
  try {
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (let registration of registrations) {
      if (registration.active) {
        const subscription = await registration.pushManager.getSubscription();
        if (subscription && subscription.endpoint === subscriptionEndpoint) {
          await subscription.unsubscribe();
          return true;
        }
      }
    }
    return false;
  } catch (error) {
    console.error("Error unsubscribing from push notifications:", error);
    return false;
  }
};

export const getOrCreateSubscription = async (registrate) => {
  const existingSubscription = await registrate.pushManager.getSubscription();
  if (existingSubscription) {
    return existingSubscription;
  }
  const registration = await navigator.serviceWorker.ready;
  const publicKey = process.env.REACT_APP_PWA_PUBLIC_KEY;
  const applicationServerKey = urlBase64ToUint8Array(publicKey);
  const newSubscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey,
  });
  return newSubscription;
};

export const servicesWorkerHandlePageLoad = async () => {
  if (!isNotificationAndSWSupported()) return;
  const isNotificationsEnabled =
    localStorage.getItem("notificationsEnabled") === "true";
  const currentPermission = Notification.permission;
  localStorage.setItem("notificationsPreference", currentPermission);
  try {
    if (isNotificationsEnabled && currentPermission === "granted") {
      let registration = await navigator.serviceWorker.getRegistration();
      if (!registration) {
        registration = await navigator.serviceWorker.register("/custom-sw.js");
      }
      const subscription = await registration.pushManager.getSubscription();
      if (!subscription) {
        await registerSW();
      } else if (
        subscription.endpoint !== localStorage.getItem("subscriptionEndpoint")
      ) {
        await saveSubscriptionToServer(subscription);
      }
    } else {
      await unsubscribeNotifications();
    }
  } catch (error) {
    console.error("Error in servicesWorkerHandlePageLoad:", error);
  }
};

export const unregisterServiceWorker = async () => {
  try {
    if ("serviceWorker" in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      registrations.forEach(async (registration) => {
        if (
          registration.active &&
          registration.active.scriptURL.includes(window.location.origin)
        ) {
          await registration.unregister();
        }
      });
    }
  } catch (error) {
    console.error("Error unregistering service worker:", error);
  }
};

export const handleFirstVisitWithoutLogin = async () => {
  if (!isNotificationAndSWSupported()) return;
  if (localStorage.getItem("action_notification")) return;
  try {
    let currentPermission = Notification.permission;
    if (currentPermission !== "granted") {
      const permission = await Notification.requestPermission();
      localStorage.setItem(
        "notificationsEnabled",
        permission === "granted" ? "true" : "false"
      );
      if (permission === "granted") {
        console.log(
          "User allowed notifications. Registering Service Worker..."
        );
        await registerSW();
      } else {
        console.log("User denied notifications.");
      }
      currentPermission = permission;
      window.parent.postMessage(
        {
          type: "actionNotificationBrowser",
          permission,
          notificationsEnabled: permission === "granted" ? "true" : "false",
        },
        "*"
      );
      localStorage.setItem("action_notification", "true");
      localStorage.setItem("notificationsPreference", permission);
    } else {
      localStorage.setItem("notificationsPreference", currentPermission);
      localStorage.setItem(
        "notificationsEnabled",
        currentPermission === "granted" ? "true" : "false"
      );
    }
  } catch (error) {
    console.error("Error handleFirstVisitWithoutLogin:", error);
  }
};

const handleNotificationPermission = (permission, isEnabled) => {
  window.parent.postMessage(
    {
      type: "actionNotificationBrowser",
      permission,
      notificationsEnabled: isEnabled.toString(),
      isOnSetting: true,
    },
    "*"
  );
  localStorage.setItem("notificationsEnabled", isEnabled.toString());
  localStorage.setItem("notificationsPreference", permission);
};

export const enableGameNotifications = async () => {
  if (!isNotificationAndSWSupported()) return;
  try {
    const permission = Notification.permission;
    if (permission === "granted") {
      handleNotificationPermission(permission, true);
      registerSW();
      return;
    }
    if (permission === "denied") {
      handleNotificationPermission(permission, false);
      return;
    }
    const newPermission = await Notification.requestPermission();
    const notificationsEnabled = newPermission === "granted";
    localStorage.setItem(
      "notificationsEnabled",
      notificationsEnabled.toString()
    );
    localStorage.setItem("notificationsPreference", newPermission);
    if (notificationsEnabled) {
      console.log("User allowed notifications");
      await registerSW();
    } else {
      console.log("User denied notifications");
    }
    window.parent.postMessage(
      {
        type: "actionNotificationBrowser",
        permission: newPermission,
        notificationsEnabled: notificationsEnabled ? "true" : "false",
      },
      "*"
    );
  } catch (error) {
    console.error("Error enabling game notifications:", error);
  }
};

const unsubscribeNotifications = async (authToken) => {
  try {
    const controller = navigator.serviceWorker.controller;
    if (controller) {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.getSubscription();
      if (subscription) {
        await subscription.unsubscribe();
      }
    }
    await removeSubscriptionToServer(authToken);
    localStorage.removeItem("subscriptionId");
    localStorage.removeItem("subscriptionEndpoint");
  } catch (error) {
    console.error("Error unsubscribing from notifications:", error);
  }
};

export const disableNotifications = async () => {
  try {
    await unsubscribeNotifications();
    localStorage.setItem("notificationsEnabled", "false");
  } catch (error) {
    console.error("Error disabling notifications:", error);
  }
};

export const handleNotificationsLogout = async (authToken) => {
  try {
    await unsubscribeNotifications(authToken);
  } catch (error) {
    console.error("Error handling notifications logout:", error);
  }
};
