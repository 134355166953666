import {
  usePrivy,
  useWallets,
  getEmbeddedConnectedWallet,
} from "@privy-io/react-auth";
import "../App.css";
import { encodeFunctionData } from "viem";
import { abiChestNFT } from "../config/abi.contract";

function useChest() {
  const urlEndpoint = process.env.REACT_APP_FISH_BE_ENDPOINT;
  const { sendTransaction } = usePrivy();
  const { wallets } = useWallets();
  const embeddedWallet = getEmbeddedConnectedWallet(wallets);
  const chestNFTContractAddress =
    process.env.REACT_APP_CHEST_NFT_CONTRACT_ADDRESS;

  const openNFTChest = async (chestId) => {
    try {
      if (embeddedWallet && (await embeddedWallet.isConnected())) {
        const data = encodeFunctionData({
          abi: abiChestNFT,
          functionName: "burnBatch",
          args: [[chestId]],
        });

        const txUiConfig = {
          header: `Open Chest`,
          description: `Open chest with ID ${chestId}`,
          buttonText: `Open`,
        };

        // Construct transaction request
        const transactionRequest = {
          to: chestNFTContractAddress,
          data: data,
        };

        const txReceipt = await sendTransaction(transactionRequest, txUiConfig);

        if (txReceipt.status === 1) {
          const backendResponse = await verifyTxHashAndProcessOpenChest(
            txReceipt.transactionHash
          );

          if (backendResponse.success) {
            return {
              success: true,
              message: "Verify successful",
              data: backendResponse.data,
            };
          } else {
            return { success: false, message: "Verify transaction failed" };
          }
        } else {
          console.error("Transaction failed:", txReceipt);
          return {
            success: false,
            message: "Transaction failed on blockchain",
          };
        }
      } else {
        console.error(
          `Transaction failed: embedded wallet ${embeddedWallet} is not connected`
        );
        return {
          success: false,
          message: `Embedded wallet is not connected, please try again`,
        };
      }
    } catch (error) {
      console.error("Send transaction failed:", error);
      return { success: false, message: "Send Transaction failed" };
    }
  };

  const verifyTxHashAndProcessOpenChest = async (txHash) => {
    try {
      const response = await fetch(urlEndpoint + "/trading/open-nft-chest", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("fishAuth")}`, // Gửi JWT token trong header
        },
        body: JSON.stringify({ transactionHash: txHash }),
      });
      const data = await response.json();

      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Error when sending transactionHash to backend:", error);
      return { success: false, message: error.message };
    }
  };

  return { openNFTChest };
}

export default useChest;
