import {
  usePrivy,
  useWallets,
  getEmbeddedConnectedWallet,
} from "@privy-io/react-auth";
import { ethers } from "ethers";
import "../App.css";

function useWithdraw() {
  const { sendTransaction } = usePrivy();
  const { wallets } = useWallets();
  const embeddedWallet = getEmbeddedConnectedWallet(wallets);

  const handleWithdraw = async (receivedWalletAddress, ethAmount) => {
    try {
      if (embeddedWallet && await embeddedWallet.isConnected()) {
        const weiValue = ethers.utils.parseEther(ethAmount);
        const hexWeiValue = ethers.utils.hexlify(weiValue);
        const transactionRequest = {
          to: receivedWalletAddress,
          value: hexWeiValue,
        };

        const txReceipt = await sendTransaction(transactionRequest);
        if (txReceipt.status === 1) {
          return {
            success: true,
            message: "Send transaction successful",
            data: txReceipt.transactionHash,
          };
        } else {
          console.error("Transaction failed:", txReceipt);
          return {
            success: false,
            message: "Transaction failed on blockchain",
          };
        }
      } else {
        console.error(
          `Transaction failed: embedded wallet ${embeddedWallet} is not connected`
        );
        return {
          success: false,
          message: `Embedded wallet is not connected, please try again`,
        };
      }
    } catch (error) {
      console.error("Send transaction failed:", error);
    }
  };

  return { handleWithdraw };
}

export default useWithdraw;
